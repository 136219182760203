//
//
//
//
//
//

/* eslint-disable */
import {fabric} from 'fabric';
import QRCode from 'qrcode';
import wx from 'weixin-js-sdk';
import axios from 'axios';
import POSTER from '@/assets/json/shop/index';
import LOGGER from '@/apis/log';
import Painter from '@/components/painter4';

const DEFAULT_APPID = 'wx60ced1c8de7908d5'
export default {
	metaInfo () {
      return {
			title: '海报制作',
		}
	},
	components: {
		Painter,
	},
	data: ()=> ({
		jsonArray: null,
		poster_id: 0,
		shop_id: 0,
		shopInfo: null,
		id: null,
		appId: '',
		posterKey: '',
	}),
	created: async function() {
		this.posterKey = new Number(new Date().getTime()).toString()
		let _this = this
		let {shop_id = 0, poster_id = 0} = this.$route.params,
			{appId = 'wx60ced1c8de7908d5'} = this.$route.query
		if(shop_id) {
			this.shop_id =  shop_id
		}
		if(poster_id) {
			this.poster_id = poster_id
		}
		if(appId) {
			this.appId = appId
		}
	},
	mounted: async function() {

		if(this.shop_id) {
			await this.loadShopInfo()
		}
		if(this.poster_id) {
			this.buildCustomJSON()
		}
	},

	methods: {

		loadShopInfo: async function() {
			let res = null
			try {
				res = await axios.get('/api-mall/agent/' + this.shop_id)
			} catch (err) {

			}
			if (res && res.data) {
				this.shopInfo = res.data
			}
		},

		buildCustomJSON:  function() {
			let demoJSON = POSTER[parseInt(this.poster_id)],
				shopId = this.shop_id,
				shopInfo = this.shopInfo,
				jsonArray = []
			for (let i = 0; i < demoJSON.length; i++) {
				const element = demoJSON[i],
					elementId = element.id || ''
				console.log(elementId)
				if (elementId == 'title') {
					element.text = shopInfo.name ?  shopInfo.name : '点击修改名称'
				}
				if (elementId == 'avatar') {
					element.url = 'https://' + (shopInfo.avatar ? shopInfo.avatar : 'doc.efreight.cn/weapp/zjhy/static/2020-12-15/default-logo.png')
				}
				if (elementId == 'qrcode') {
					element.content = 'http://agent-mall.efreight.cn/shop/' + shopInfo.id + '?posterId=' + this.id + '&posterKey=' + this.posterKey
				}
				jsonArray.push(element)
			}
			console.log(jsonArray)
			this.jsonArray = jsonArray
		},


	}
}
/*
wiki https://github.com/fabricjs/fabric.js/wiki/FAQ
1. image.clipPath != null的时候如果image.setSrc, 图像不会立刻生效，且存在BUG
2. 需要支持editAble对象请勿设置borderRadius
3. image加载png格式的base64图像显示白底 -> 设置light:#0000即可
4. fabric.object可以使用set('attr', {...})来传递自定义变量，使用obj.attr来获取
*/
